<template>
  <div class="share">
    <div class="text">长按保存图片</div>
    <img :src="share.banner" />
  </div>
</template>

<script>
import { isWeixin } from "@utils/index";
import { wechatEvevt } from "@libs/wechat";
import { shareBill } from "@api/user";
const _isWechat = isWeixin();
export default {
  data() {
    return {
      htmlUrl: "",
      share: {},
    };
  },
  created() {
    this.$dialog.loading.open();
    this.getShareData();
  },
  methods: {
    // 获取分享信息
    getShareData() {
      shareBill()
        .then((res) => {
          this.share = res.data.data;
          this.$dialog.loading.close();
          this.setOpenShare();
        })
        .catch((err) => {
          this.$dialog.loading.close();
          this.$dialog.error(err.msg);
        });
    },

    // 分享
    setOpenShare: function () {
      var href = window.location.origin + "/?spread=" + this.share.uid;
      var share = this.share;

      if (_isWechat) {
        var configAppMessage = {
          desc: share.synopsis,
          title: share.title,
          link: href,
          imgUrl: share.img,
        };
        wechatEvevt(
          [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareAppMessage",
            "onMenuShareTimeline",
          ],
          configAppMessage
        )
          .then((res) => {
            console.log(res);
          })
          .catch((res) => {
            if (res.is_ready) {
              res.wx.updateAppMessageShareData(configAppMessage);
              res.wx.onMenuShareAppMessage(configAppMessage);
              res.wx.updateTimelineShareData(configAppMessage);
              res.wx.onMenuShareTimeline(configAppMessage);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  background: #fafafa;
  height: 100%;
  .text {
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.3rem;
    font-weight: 700;
    text-align: center;
  }
  img {
    width: 100%;
    height: 12.5rem;
    position: relative;
  }
  p {
    font-size: 0.26rem;
    position: absolute;
    bottom: 1.4rem;
    left: 3.5rem;
    width: 3rem;
    line-height: 0.5rem;
    font-weight: 700;
  }
  .content {
    position: absolute;
    top: 1rem;
    left: 0px;
    width: 100%;
    height: 12.5rem;
  }
}
</style>